import React from "react"
import Layout from "../components/layout.js"

const Index = () => {
  return (
    <Layout pageTitle="Welcome">
      {/* <h1 className="main__page-title">This is not a blog.</h1> */}
      <div>
        <p>My name is Chris Welchhans. This website is meant to compliment my formal resume. In these pages you'll find additional
          information about me, including a full list of my professional experiences and examples
          of recent projects.</p>
        <p>Use the menu (top right) to navigate, or you can use the links in the footer to contact
          me via email or LinkedIn.
        </p>
        <p>Thank you for visiting.</p>
      </div>
    </Layout>
  )
}

export default Index